<template>
    <div class="app">
        <Row class="errors-center" type="flex" justify="center">
          <Col  span="16">
            <div class="errors-background">
              <img src="../../assets/images/404.png" alt="404"/>
            </div>
          </Col>
        </Row>
    </div>
</template>

<script>
    export default {
      name: "404"
    }
</script>

<style scoped type="less">
    .errors-center {
      margin-top: 100px;
      padding-bottom: 100px;
    }

    .errors-background {
      text-align: center;
    }
</style>
